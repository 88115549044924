<template>
	<div>
		<ts-page-title :title="$t('basicSalaryHistory.pageTitle')" :breadcrumb="[
			{ text: $t('home'), href: '/' },
			{
				text: $t('basicSalaryHistory.pageTitle'),
				active: true
			}
		]" />
		<div class="tw-flex tw-space-x-4">
			<ts-panel class="col-md-3">
				<ts-panel-wrapper>
					<div class="mb-3">
						<label class="col-label form-label tw-text-xs required">{{
							$t('basicSalaryHistory.orgChartStructureId')
						}}</label>
						<div class="col-md-12">
							<Select v-model="org_chart_structure_id" :loading="loading" class="from-label"
								@on-change="employee_id = null" filterable remote :remote-method="searchOrgChartStruture">
								<Option v-for="(org, index) in orgChartStructure" :value="org.org_chart_structure_id"
									:key="index">
									{{ org.org_chart_structure }}
								</Option>
							</Select>
							<div class="text-danger" v-if="errors.has('org_chart_structure_id')">
								{{ errors.first("org_chart_structure_id") }}
							</div>
						</div>
					</div>
					<div class="mb-3">
						<label class="col-label form-label tw-text-xs required">{{
							$t('basicSalaryHistory.cycleYear')
						}}</label>
						<DatePicker type="year" format="yyyy" placeholder="Select Year" @on-change="onChangeYear"
							:value="year" style="width:317px"></DatePicker>
					</div>
					<div>
						<div class="tw-flex tw-justify-end tw-space-x-2 tw-sh tw-mt-3">
							<ts-button @click.prevent="exportExcel" class="btn-gray" :waiting="exporting">
								{{ $t('export') }}
							</ts-button>
							<ts-button color="primary" @click="fetchData" :waiting="waiting"
								:disabled="waiting_new || waiting">
								{{ $t('view') }}
							</ts-button>
						</div>
					</div>
				</ts-panel-wrapper>
			</ts-panel>
			<ts-panel class="col-md-9">
				<Table :columns="columns" :data="resources" size="small" :loading="loading" class="tw-rounded-md" stripe>
				</Table>
				<div class="tw-flex tw-items-center tw-justify-end tw-p-4">
					<ts-i-pagination v-model="pagination" @navigate="fetchData"></ts-i-pagination>
				</div>
			</ts-panel>
		</div>
	</div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import { debounce } from 'lodash';
import moment from 'moment'
export default {
	name: 'basic-salary-history',
	data() {
		return {
			errors: new Errors(),
			exporting: false,
			loading: false,
			waiting_new: false,
			waiting: false,
			EmployeeLoading: false,
			org_chart_structure_id: null,
			employee_id: null,
			year: moment().format('YYYY'),
			orgChartStructure: [],
			employeeProfile: []
		}
	},
	computed: {
		...mapState("report/basicSalaryHistory", [
			"resources",
			"pagination",
			"orgChartStructures",
		]),
		columns() {
			return [
				{
					title: this.$t('basicSalaryHistory.codeId'),
					key: 'card_id',
					sortable: true,
					width: '100px'
				},
				{
					title: this.$t('basicSalaryHistory.employeeName'),
					key: 'employee_name_en',
					width: '160px',
					sortable: true
				},
				{
					title: this.$t('basicSalaryHistory.positionId'),
					key: 'job_title',
					width: '135px',
					sortable: true
				},
				{
					title: this.$t('basicSalaryHistory.jan'),
					key: 'Jan',
					width: '60px'
				},
				{
					title: this.$t('basicSalaryHistory.feb'),
					key: 'Feb',
					width: '60px'
				},
				{
					title: this.$t('basicSalaryHistory.mar'),
					key: 'Mar',
					width: '65px'
				},
				{
					title: this.$t('basicSalaryHistory.apr'),
					key: 'Apr',
					width: '60px'
				},
				{
					title: this.$t('basicSalaryHistory.may'),
					key: 'May',
					width: '65px'
				},
				{
					title: this.$t('basicSalaryHistory.jun'),
					key: 'Jun',
					width: '60px'
				},
				{
					title: this.$t('basicSalaryHistory.jul'),
					key: 'Jul',
					width: '60px'
				},
				{
					title: this.$t('basicSalaryHistory.aug'),
					key: 'Aug',
					width: '60px'
				},
				{
					title: this.$t('basicSalaryHistory.sep'),
					key: 'Sep',
					width: '60px'
				},
				{
					title: this.$t('basicSalaryHistory.oct'),
					key: 'Oct',
					width: '85px'
				},
				{
					title: this.$t('basicSalaryHistory.nov'),
					key: 'Nov',
					width: '65px'
				},
				{
					title: this.$t('basicSalaryHistory.dec'),
					key: 'Dec',
					width: '60px'
				},
			]
		}
	},
	created() {
		this.fetchResource({
			fnName: 'orgChartStructure'
		})
	},
	methods: {
		...mapActions("report/basicSalaryHistory", ['formViewModel']),
		async fetchResource(attributes) {
			this.loading = true
			let response = await this.formViewModel({
				params: attributes
			})

			if (attributes.fnName) {
				attributes.fnName.split(',').forEach(fn => {
					this[fn] = response[fn]
				});
			}
			this.loading = false
		},
		async fetchData(attributes) {
			this.loading = true;
			this.errors = new Errors();
			this.$store
				.dispatch("report/basicSalaryHistory/fetch", {
					org_chart_structure_id: this.org_chart_structure_id,
					cycle_year: this.year,
					...attributes
				})
				.then(() => {
					this.loading = false;
				})
				.catch(error => {
					this.loading = false;
					this.errors = new Errors(error.errors);
					this.notice({ type: "error", text: error.message });
				});
		},
		exportExcel(attributes) {
			this.errors = new Errors();
			this.exporting = true;

			this.$store
				.dispatch(
					"report/basicSalaryHistory/exportRawData", {
					org_chart_structure_id: this.org_chart_structure_id,
					cycle_year: this.year,
					...attributes
				}
				)
				.then(response => {
					const anchor = document.createElement('a');
					anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(response.data);
					anchor.target = '_blank';
					anchor.download = 'report-basic-salary-history.csv';
					anchor.click();
				})
				.catch(err => {
					let error = JSON.parse(
						String.fromCharCode.apply(null, new Uint8Array(err))
					)
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => (this.exporting = false))
		},
		onChangeYear(year) {
			this.year = year
		},
		searchOrgChartStruture: debounce(async function (query) {
			var avoidSearch = this.orgChartStructure.find(org => org.org_chart_structure == query.trim());
			if (avoidSearch) return
			this.fetchResource({
				fnName: 'orgChartStructure',
				org_search: query,
			})
		}, 500),
		notice(not) {
			this.$Notice[not.type]({
				title: "DAILY ATTENDANCES",
				desc: not.text
			});
		}
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit("report/basicSalaryHistory/RESET_STATE");
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fetchResource({
				fnName: 'orgChartStructure',
			});
		});
	}
}
</script>

<style lang="scss" scoped></style>